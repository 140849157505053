.fc .fc-toolbar{
    flex-wrap: wrap;
}
.fc .fc-toolbar.fc-header-toolbar{
    margin-left: 1.5rem;
}
.fc .fc-toolbar-chunk{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 7px;
}
.fc .fc-toolbar-chunk button{
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    text-transform: uppercase;
    margin: 3px;
}
.fc-button-group{
    margin: 3px;
}

.fc-button-group button{
    margin: 0 !important;
}


.fc-direction-ltr .fc-daygrid-event.fc-event-end{
    overflow: auto;
}