.animate_button {
  background-color: #2f80ed;
  color: white;
  text-decoration: none;
  border-radius: 12px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 35px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
  -webkit-transition: max-width 0s;
  transition: max-width 0s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 4px;
}

.animate_button:hover {
  max-width: 300px;
}

.animate_icon {
  padding: 7px;
  display: flex;
  align-items: center;
}

.animate_text {
  white-space: nowrap;
  padding-right: 10px;
  padding-left: 4px;
  color: #f2f2f2;
  font-family: "Satoshi-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}
