@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
.body {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.arrowright {
  border: solid grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.calendericon {
  width: 15px;
  height: 15px;
  /* position : absolute; */
  margin-left: -15px;
}
.calendericonLeft {
  width: 15px;
  height: 15px;
  /* position : absolute; */
  margin-left: 0px;
}

.nice-dates {
  position: relative;
}

.date-range {
  padding: 10px;
}
.date-range1 {
  padding: 10px;
}
.date-left {
  display: contents;
  float: left;
}
.date-left1 {
  display: contents;
  float: left;
  display: flex;
  align-items: center;
  border-bottom: 2px solid;
  margin-left: 25px;
}
.date-right {
  float: right;
}

.date-range input {
  width:25%;
  margin-left: 5px;
  border: none;
  
}
.date-range1 input {
  width: 35%;
  margin-left: 5px;
  border: none;
  
}

.date-range input:focus {
  border: none;
}
.date-range1 input:focus {
  border: none;
}

.outer {
  width: 80%;
  height: 10rem;
  margin: auto;
  margin-top: 2rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  border-radius: 4px 4px;
  border: 1px solid #d9d7d7;
  word-break: break-all;
}
@media only screen and (min-width: 768px) and (max-width: 1203px) {
  .outer-box {
    height: 12rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .outer {
    width: 80% !important;
    margin-bottom: 0 !important; 
  }

  .MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
    width: 45px;
    height: 45px;
  }
  .small-width {
    width: 80% !important;
  }
  .recharts-responsive-container .Piechart-container-30 {
    height: auto !important;
  }
  .company {
    font-size: 15px !important;
  }

  .companytext {
    text-align: center;
    width: 100% !important;
    height: auto !important;
  }
  .company p {
    margin-left: 0px !important;
  }
  .date-range input {
    width: 29%;
    margin-left: 5px;
    border: none;
  }
  .date-range1 input {
    width: 50%;
    margin-left: 5px;
    border: none;
  }
  .companyicon {
    width: 50% !important;
  }
  .inner2 {
    font-size: 1rem !important;
    line-height: 1px !important;
  }
  .inner21 {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  
  }
  .inner21 .margin-class {
    margin: 15px 2px !important;
  }
  .inner22 {
    width: 100% !important;
  }
  .inner221main {
    width: 90% !important;
    height: 100% !important;
  }
  .inner221 {
    height: auto !important;
  }
  .inner222 {
    margin: 10px !important;
  }
  .timeTakenCard {
    width: 80% !important;
    height: auto !important;
  }
  .companyTimeTaken {
    font-size: 15px !important;
    line-height: 26px !important;
  }
  .companyTimeTaken section {
    font-size: 15px !important;
  }
  table {
    width: 100% !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-size: 12px !important;
    line-height: 26px !important;
  }
  .outer .short {
    width: 80% !important;
  }
  .buttons {
    display: contents !important;
    margin-top: 0rem !important;
    margin-bottom: 5rem !important;
  }
  .buttons input {
    height: 30px !important;
    margin: 10px;
    padding: 5px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    line-height: 18px !important;
    font-size: 12px !important;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 24%);
    border-radius: 2px;
  }
  .outer h4 {
    padding-left: 10%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .outer p {
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 3px;
    
  }
  .compnayLevelRightPnale {
    margin: -110px 0px 0px 20px !important;
    width: 100%;
  }
  .piecolor {
    width: 100% !important;
  }
  .outer-box {
    height: 26rem !important;
  }
  .input1 {
    margin: 5px !important;
    padding: 2px !important;
  }
  .Piechart-container-23 {
    width: 100% !important;
    height: auto !important;
  }
  .recharts-wrapper .recharts-surface {
    width: 100% !important;
    height: auto !important;
  }
  .recharts-wrapper {
    width: 88% !important;
    height: auto !important;
  }
  .recharts-responsive-container .Piechart-container-30 {
    height: auto !important;
  }
  .outer .short .small-width {
    width: 80% !important;
  }
}
@media only screen and (max-width: 468px) {
  .recharts-wrapper .recharts-surface {
    width: 55% !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 468px) and (max-width: 768px) {
  .recharts-wrapper .recharts-surface {
    width: 65% !important;
    height: auto !important;
    padding-right: 80px !important;
  }
  .outer .short .small-width {
    width: 80% !important;
  }
  .recharts-responsive-container .Piechart-container-30 {
    height: auto !important;
  }
  .nice-dates-popover {
    width: 100% !important;
  }
  .buttons{
    display: contents !important;
  }
}
@media (max-width: 959.95px) {
  .Piechart-container-30 {
    width: 100%;
    height: auto !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .outer {
    width: 80% !important;
  }
  .outer-box1 {
    width: 80% !important;
  }
  .outer-box {
    width: 80% !important;
  }
  .timeTakenCard {
    width: 80% !important;
    height: auto !important;
  }
  .recharts-wrapper .recharts-surface {
    width: 80% !important;
    height: auto !important;
  }
  table {
    width: 100% !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-size: 12px !important;
    line-height: 26px !important;
  }
  .buttons{
    display: contents !important;
  }
}
@media only screen and (min-width: 600px) {
  .nice-dates-popover {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1024px) {
  .outer-box {
    width: 80% !important;
  }
  .outer-box1 {
    width: 80% !important;
  }

  .timeTakenCard {
    width: 80% !important;
    height: auto !important;
  }
  .nice-dates-popover {
    width: 100% !important;
  } 
}
@media only screen and (max-width: 500px) {
.reportbtn{
  min-width: 0 !important;
}
  
}


.timeTakenCard {
  height: 22.5rem;
  /* width        : 50%; */
  /* height       : 10rem; */
  margin: auto;
  margin-top: 2rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px;
  border: 1px solid #d9d7d7;
  width: 50%;
  /* height    : 31rem; */
  background-color: white;
  word-break: break-word;
  overflow: hidden;
}

.inner1 {
  background: #e5e5e5;
  /* height: 3rem; */
  padding: 1rem;
  word-break: break-word;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  text-align: left;
  padding-right: 5px;
}

.inner2 {
  max-height: 7rem;
  font-size: 1rem;
}

.inner21 {
  width: 65%;
  /* height               : 7rem; */
  word-break: break-all;
  display: grid;
  grid-gap: auto;
  grid-template-columns: 1fr 1fr;
  float: left;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  text-align: left; 
  
}

.inner22 {
  width: 35%;
  height: 7rem;
  word-break: break-all;
  float: right;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.inner221 {
  height: 3.5rem;
  word-break: break-all;
  display: flex;
  justify-content: center;
}

.inner221main {
  width: 80%;
  height: 85%;
  margin: auto;
  border-radius: 4px;
  border: 1px solid rgb(182, 177, 177);
}

.inner222 {
  margin-right: 48px;
  float: right;
  height: 3.5rem;
  /* height         : 3.5rem;
    word-break     : break-all;
    display        : flex;
    justify-content: center; */
}

.input1 {
  width: 96px;
  height: 28px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 0px 0px;
  font-size: 15px;
  display: inline-block;
  margin-left: 15px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.input2 {
  background: #3f51b5;
  color: white;
}

.company {
  height: 4.5rem;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}

.company p {
  margin-left: 100px;
}

.companyicon {
  /* background-color: cyan; */
  width: 30%;
  font-size: 22px;
  height: 100%;
  display: flex;
  align-items: center;
}

.companytext {
  /* background-color: yellow; */
  width: 70%;
  height: 100%;
}

.companyicon span {
  margin-left: auto;
  margin-right: 15%;
  /* background-color: black; */
}

/* 
By Nishant */
/* .asdf {
    margin-top: 1rem;
} */

.bottomMargin {
  margin-bottom: 4rem;
}

.innerCardMargin {
  margin: -50px 0 0 0;
}

.short {
  /* width     : 55%!important; */
  /* height    : 31rem; */
  height: auto !important;
  background-color: white;
  word-break: break-all;
  overflow: hidden;
}

.pie {
  background-color: white;
  width: 10%;
  height: 0%;
  float: left;
}

.pie h4 {
  padding-left: 10%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 26px;
}

.pie p {
  padding-left: 10%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
}

.piecolor {
  background-color: white;
  float: right;
  width: 30%;
  height: 100%;
}
.fonts {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.compnayLevelRightPnale {
  margin: 135px 0px 0px 0px;
  width: 100%;
}

.countMargin {
  margin: 7px 0px 26px -66px;
}
.MuiBox-root-170 b {
  margin-left: -23px;
}
.MuiBox-root-36 b {
  margin-left: -32px;
}
.MuiBox-root-44 b {
  margin-left: -20px;
}
.MuiBox-root-162 b {
  margin-left: -36px;
}
.companyTimeTaken {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  margin: 6%;
  margin-top: 5%;
  font-size: 24px;
  line-height: 26px;
  color: #454545;
  /* background-color: red; */
}

.companyTimeTaken section {
  font-weight: bold;
  font-size: 36px;
  line-height: 26px;
}

table {
  border-collapse: collapse;
  width: 80%;
  max-width: 80%;
  height: 50%;
  margin: auto;
  margin-top: 4%;

  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
}

table td,
table th {
  /* padding      : 8px; */
  padding-left: 5px;
  /* padding-right: 4%; */
}

table tr th {
  text-align: left;
  border-bottom: 1px solid #d1cfcf;
  border-left: 1px solid #d1cfcf;
}

table tr th:first-child {
  border-left: 0;
}

table tr td {
  border-left: 1px solid #d1cfcf;
}

table tr td:first-child {
  border-left: 0;
}

table {
  border-collapse: collapse;
}

.companyExpand {
  width: 100%;
  height: 48px;
  background-color: #454545;
  border-radius: 0px 0px 4px 4px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}




.ActionTaken {
  background-color: lightgrey;
  width: 100%;
  height: 100%;
}

.rating {
  height: auto;
  margin: 35px;
  display: flex;
  justify-content: flex-start;
}

.buttons {
  width: 45%;
  /* height          : 10rem; */
  height: 0rem;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons input {
  height: 60px;
  margin: 10px;
  padding: 10px;
  width: 224px;
  line-height: 36px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}
.MuiBox-root .MuiBox-root-29 {
  height: auto !important;
}
/* .MuiBox-root-27 {
  display: flex;
  height: 1rem;
  margin-bottom: 3.7rem !important;
} */
/* .MuiSvgIcon-root{
width: 54px!important;
height: 54px!important;
} */
.MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
  width: 54px;
  height: 54px;
}
.recharts-responsive-container .Piechart-container-30 {
  display: flex;
  flex-direction: column-reverse;
}
.outer h4 {
  padding-left: 5%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 26px;
  word-break: break-word;
}
.outer p {
  /* padding-left: 50%; */
  /* padding-right: 43%; */
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 12px;
  text-align: left;
  
}
/* .MuiBox-root-88 {
  width: 0rem !important;
} */

.outer .companyExpand p {
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  line-height: 26px !important;
}

.space-btm {
  margin: 25px !important;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow-y: scroll;
  display: block;
}
.date-stye {
  font-size: 10px;
  line-height: 16px;
  padding-left: 3%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}
.date-stye1 {
  font-size: 10px;
  line-height: 16px;
  /* padding-left: 3%; */
  padding: 5px 0;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.body {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.arrowright {
  border: solid grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.calendericon {
  width: 15px;
  height: 15px;
  /* position : absolute; */
  margin-left: -15px;
}

.date-range {
  padding: 10px;
}
.date-left {
  display: contents;
  float: left;
}
.date-right {
  float: right;
}

.date-range input {
  width: 25%;
  margin-left: 5px;
  border: none;
}

.date-range input:focus {
  border: none;
}

.outer {
  width: 60%;
  height: 10rem;
  margin: auto;
  margin-top: 2rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  border-radius: 4px 4px;
  border: 1px solid #d9d7d7;
  word-break: break-all;
}
@media only screen and (min-width: 768px) and (max-width: 1203px) {
  .outer-box {
    height: 12rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .outer {
    width: 80% !important;
    margin-bottom: 5rem !important;
  }

  .MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
    width: 45px;
    height: 45px;
  }
  .small-width {
    width: 80% !important;
  }
  .recharts-responsive-container .Piechart-container-30 {
    height: auto !important;
  }
  .company {
    font-size: 15px !important;
  }

  .companytext {
    text-align: center;
    width: 100% !important;
    height: auto !important;
  }
  .company p {
    margin-left: 0px !important;
  }
  .date-range input {
    width: 29%;
    margin-left: 5px;
    border: none;
  }
  .companyicon {
    width: 30% !important;
  }
  .inner2 {
    font-size: 1rem !important;
    line-height: 1px !important;
  }
  .inner21 {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .inner21 .margin-class {
    margin: 15px 2px !important;
  }
  .inner22 {
    width: 100% !important;
  }
  .inner221main {
    width: 90% !important;
    height: 100% !important;
  }
  .inner221 {
    height: auto !important;
  }
  .inner222 {
    margin: 10px !important;
  }
  .timeTakenCard {
    width: 80% !important;
    height: auto !important;
  }
  .companyTimeTaken {
    font-size: 15px !important;
    line-height: 26px !important;
  }
  .companyTimeTaken section {
    font-size: 15px !important;
  }
  table {
    width: 100% !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-size: 12px !important;
    line-height: 26px !important;
  }
  .outer .short {
    width: 80% !important;
  }
  .buttons {
    margin-top: 0rem !important;
    margin-bottom: 5rem !important;
  }
  .buttons input {
    height: 35px !important;
    margin: 10px;
    padding: 5px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    line-height: 18px !important;
    font-size: 12px !important;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 24%);
    border-radius: 2px;
  }
  .outer h4 {
    padding-left: 10%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .outer p {
    padding-left: 10%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 3px;
  }
  .compnayLevelRightPnale {
    margin: -110px 0px 0px 20px !important;
    width: 100%;
  }
  .piecolor {
    width: 100% !important;
  }
  .outer-box {
    height: 26rem !important;
  }
  .input1 {
    margin: 5px !important;
    padding: 2px !important;
  }
  .Piechart-container-23 {
    width: 100% !important;
    height: auto !important;
  }
  .recharts-wrapper .recharts-surface {
    width: 100% !important;
    height: auto !important;
  }
  .recharts-wrapper {
    width: 80% !important;
    height: auto !important;
  }
  .recharts-responsive-container .Piechart-container-30 {
    height: auto !important;
  }
  .outer .short .small-width {
    width: 80% !important;
  }
}
@media only screen and (max-width: 768px) {
  .recharts-wrapper .recharts-surface {
    width: 65% !important;
    height: auto !important;
  }
  .outer .short .small-width {
    width: 80% !important;
  }
  .recharts-responsive-container .Piechart-container-30 {
    height: auto !important;
  }
  .nice-dates-popover {
    width: 100% !important;
  }
}
@media (max-width: 959.95px) {
  .Piechart-container-30 {
    width: 100%;
    height: auto !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .outer {
    width: 60% !important;
  }
  .outer-box1 {
    width: 80% !important;
  }
  .outer-box {
    width: 80% !important;
  }
  .timeTakenCard {
    width: 60% !important;
    height: auto !important;
  }
  .recharts-wrapper .recharts-surface {
    width: 100%;
    height: auto !important;
  }
  .recharts-wrapper .recharts-surface {
    width: 100% !important;
    height: auto !important;
  }
  table {
    width: 100% !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-size: 12px !important;
    line-height: 26px !important;
  }
}
@media only screen and (min-width: 770px) {
  /* .nice-dates-popover {
    width: 30% !important;
  } */
}
@media only screen and (min-width: 1024px) {
  .outer-box {
    width: 70% !important;
  }
  .outer-box1 {
    width: 70% !important;
  }

  .timeTakenCard {
    width: 60% !important;
    height: auto !important;
  }
  /* .nice-dates-popover {
    width: 30% !important;
  } */
}

.timeTakenCard {
  height: 22.5rem;
  /* width        : 50%; */
  /* height       : 10rem; */
  margin: auto;
  margin-top: 2rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px;
  border: 1px solid #d9d7d7;
  word-break: break-all;
  width: 50%;
  /* height    : 31rem; */
  background-color: white;
  word-break: break-all;
  overflow: hidden;
}

.inner1 {
  background: #e5e5e5;
  height: 1rem;
  padding: 1rem;
  word-break: break-all;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.inner2 {
  max-height: 7rem;
  font-size: 1rem;
}

.inner21 {
  width: 65%;
  /* height               : 7rem; */
  word-break: break-all;
  display: grid;
  grid-gap: auto;
  grid-template-columns: 1fr 1fr;
  float: left;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.inner22 {
  width: 35%;
  height: 7rem;
  word-break: break-all;
  float: right;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.inner221 {
  height: auto !important;
  word-break: break-all;
  display: flex;
  justify-content: center;
}

.inner221main {
  width: 80%;
  height: 85%;
  margin: auto;
  border-radius: 4px;
  border: 1px solid rgb(182, 177, 177);
}

.inner222 {
  margin-right: 48px;
  float: right;
  height: 3.5rem;
  /* height         : 3.5rem;
    word-break     : break-all;
    display        : flex;
    justify-content: center; */
}

.input1 {
  width: 96px;
  height: 28px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 0px 0px;
  font-size: 15px;
  display: inline-block;
  margin-left: 15px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.input2 {
  background: #3f51b5;
  color: white;
}

.company {
  height: 4.5rem;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}

.company p {
  margin-left: 100px;
}

.companyicon {
  /* background-color: cyan; */
  width: 30%;
  font-size: 22px;
  height: 100%;
  display: flex;
  align-items: center;
}

.companytext {
  /* background-color: yellow; */
  width: 70%;
  height: 100%;
}

.companyicon span {
  margin-left: auto;
  margin-right: 15%;
  /* background-color: black; */
}

/* 
By Nishant */
/* .asdf {
    margin-top: 1rem;
} */

.bottomMargin {
  margin-bottom: 4rem;
}

.innerCardMargin {
  margin: -50px 0 0 0;
}

.short {
  /* width     : 55%!important; */
  /* height    : 31rem; */
  height: auto !important;
  background-color: white;
  word-break: break-all;
  overflow: hidden;
}

.pie {
  background-color: white;
  width: 70%;
  height: 0%;
  float: left;
}

.pie h4 {
  padding-left: 10%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 26px;
}

.pie p {
  padding-left: 10%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
}

.piecolor {
  background-color: white;
  float: right;
  width: 30%;
  height: 100%;
}
.fonts {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.compnayLevelRightPnale {
  margin: 135px 0px 0px 0px;
  width: 100%;
}

.countMargin {
  margin: 7px 0px 26px -66px;
}
.MuiBox-root-170 b {
  margin-left: -23px;
}
.MuiBox-root-36 b {
  margin-left: -32px;
}
.MuiBox-root-44 b {
  margin-left: -20px;
}
.MuiBox-root-162 b {
  margin-left: -36px;
}
.companyTimeTaken {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  margin: 6%;
  margin-top: 5%;
  font-size: 24px;
  line-height: 26px;
  color: #454545;
  /* background-color: red; */
}

.companyTimeTaken section {
  font-weight: bold;
  font-size: 36px;
  line-height: 26px;
}

table {
  border-collapse: collapse;
  width: 80%;
  max-width: 80%;
  height: 50%;
  margin: auto;
  margin-top: 4%;

  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
}

table td,
table th {
  /* padding      : 8px; */
  padding-left: 5px;
  /* padding-right: 4%; */
}

table tr th {
  text-align: left;
  border-bottom: 1px solid #d1cfcf;
  border-left: 1px solid #d1cfcf;
}

table tr th:first-child {
  border-left: 0;
}

table tr td {
  border-left: 1px solid #d1cfcf;
}

table tr td:first-child {
  border-left: 0;
}

table {
  border-collapse: collapse;
}

.companyExpand {
  width: 100%;
  height: 48px;
  background-color: #454545;
  border-radius: 0px 0px 4px 4px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActionTaken {
  background-color: lightgrey;
  width: 100%;
  height: 100%;
}

.rating {
  height: auto;
  margin: 35px;
  display: flex;
  justify-content: flex-start;
}

.buttons {
  width: 45%;
  /* height          : 10rem; */
  height: 0rem;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons input {
  height: 60px;
  margin: 10px;
  padding: 10px;
  width: 224px;
  line-height: 36px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}
.MuiBox-root .MuiBox-root-29 {
  height: auto !important;
}
/* .MuiBox-root-27 {
  display: flex;
  height: 1rem;
  margin-bottom: 3.7rem !important;
} */
/* .MuiSvgIcon-root{
width: 54px!important;
height: 54px!important;
} */
.MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
  width: 54px;
  height: 54px;
}
.recharts-responsive-container .Piechart-container-30 {
  display: flex;
  flex-direction: column-reverse;
}
.outer h4 {
  padding-left: 5%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 26px;
}
.outer p {
  padding-left: 5%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 12px;
}
/* .MuiBox-root-88 {
  width: 0rem !important;
} */

.outer .companyExpand p {
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  line-height: 26px !important;
}

.space-btm {
  margin: 25px !important;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow-y: scroll;
  display: block;
}

.date-stye {
  font-size: 10px;
  line-height: 16px;
  padding-left: 3%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.Disputes_animate_button__2Y54f {
  background-color: #2f80ed;
  color: white;
  text-decoration: none;
  border-radius: 12px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 35px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
  transition: max-width 0s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 4px;
}

.Disputes_animate_button__2Y54f:hover {
  max-width: 300px;
}

.Disputes_animate_icon__3tvre {
  padding: 7px;
  display: flex;
  align-items: center;
}

.Disputes_animate_text__35giv {
  white-space: nowrap;
  padding-right: 10px;
  padding-left: 4px;
  color: #f2f2f2;
  font-family: "Satoshi-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

div.rendered-react-keyed-file-browser div.action-bar {
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
}
div.rendered-react-keyed-file-browser div.action-bar input[type="search"] {
  display: block;
  flex-grow: 2;
  min-width: 200px;
  padding: 0.25rem 0.5rem;
  line-height: 1em;
  margin-bottom: 0.5rem;
  border: 0.1rem solid #ddd;
}
div.rendered-react-keyed-file-browser div.action-bar .item-actions {
  text-align: right;
  margin: 0;
  padding: 0;
}
div.rendered-react-keyed-file-browser div.action-bar ul.item-actions {
  display: block;
  flex-grow: 1;
  min-width: 200px;
  margin-left: 10px;
  white-space: nowrap;
}
div.rendered-react-keyed-file-browser div.action-bar ul.item-actions li {
  display: inline-block;
  margin: 0;
}
div.rendered-react-keyed-file-browser
  div.action-bar
  ul.item-actions
  li:not(:last-child) {
  margin-right: 0.5rem;
}

div.rendered-react-keyed-file-browser div.files table {
  width: 100%;
  margin-bottom: 2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.rendered-react-keyed-file-browser div.files table th,
div.rendered-react-keyed-file-browser div.files table td {
  font-weight: normal;
  text-align: left;
  margin: 0;
  padding: 0.1rem;

}
div.rendered-react-keyed-file-browser div.files table td {
  line-break: anywhere;
  
}
@media only screen and (min-width: 700px) {
  div.rendered-react-keyed-file-browser div.files table td {
    padding: 0.5rem;
  }
}
div.rendered-react-keyed-file-browser div.files table th {
  font-weight: bold;
}
div.rendered-react-keyed-file-browser div.files table th.size,
div.rendered-react-keyed-file-browser div.files table th.modified,
div.rendered-react-keyed-file-browser div.files table td.size,
div.rendered-react-keyed-file-browser div.files table td.modified {
  text-align: right;
}
div.rendered-react-keyed-file-browser div.files table th.name i,
div.rendered-react-keyed-file-browser div.files table td.name i {
  padding-right: 0.5rem;
}
div.rendered-react-keyed-file-browser div.files table thead th {
  border-bottom: 0.1rem solid #ddd;
}
div.rendered-react-keyed-file-browser div.files table tr:not(:last-child) td {
  border-bottom: 0.1rem solid #eee;
}
div.rendered-react-keyed-file-browser div.files table td.name {
  padding-left: 0.8rem;
}
div.rendered-react-keyed-file-browser div.files table td.name form.renaming {
  display: flex;
  align-items: center;
}
div.rendered-react-keyed-file-browser div.files table td.name form.renaming i {
  flex-grow: 0;
  flex-shrink: 0;
}
div.rendered-react-keyed-file-browser
  div.files
  table
  td.name
  form.renaming
  input[type="text"] {
  flex: 1 1;
}
div.rendered-react-keyed-file-browser div.files table tr td {
  cursor: pointer;
}
div.rendered-react-keyed-file-browser div.files table tr.selected td {
  font-weight: bold;
}
div.rendered-react-keyed-file-browser div.files table tr.selected td input,
div.rendered-react-keyed-file-browser div.files table tr.selected td button {
  font-weight: normal;
}
div.rendered-react-keyed-file-browser div.files table tr.selected td.name {
  position: relative;
}
div.rendered-react-keyed-file-browser
  div.files
  table
  tr.selected
  td.name:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 0.3rem;
  height: 100%;
  background: #1d4567;
}
div.rendered-react-keyed-file-browser div.files table tr.dragover td,
div.rendered-react-keyed-file-browser div.files table tr.dragover th {
  background: #eee;
}

div.rendered-file-browser div.files li.file.pending,
div.rendered-file-browser div.files li.file.dragging,
div.rendered-file-browser div.files li.folder.pending,
div.rendered-file-browser div.files li.folder.dragging {
  opacity: 0.4;
}

div.rendered-file-browser div.files li.file.dragover,
div.rendered-file-browser div.files li.folder.dragover {
  background: #eee;
}

div.rendered-file-browser div.files li.file.selected > div.item,
div.rendered-file-browser div.files li.folder.selected > div.item {
  color: #fff;
  background: #ccc;
}
div.rendered-file-browser div.files li.file.selected > div.item .text-muted,
div.rendered-file-browser div.files li.folder.selected > div.item .text-muted {
  color: #e6e6e6;
}
div.rendered-file-browser div.files li.file.selected > div.item a:not(.btn),
div.rendered-file-browser
  div.files
  li.file.selected
  > div.item.folder
  a:not(.btn),
div.rendered-file-browser div.files li.file.selected > div.item i,
div.rendered-file-browser div.files li.folder.selected > div.item a:not(.btn),
div.rendered-file-browser
  div.files
  li.folder.selected
  > div.item.folder
  a:not(.btn),
div.rendered-file-browser div.files li.folder.selected > div.item i {
  color: #fff;
}

div.rendered-file-browser div.files ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  grid-template-columns: repeat(3, 1fr);
}
div.rendered-file-browser div.files ul li.folder {
  grid-column: 1/4;
}
div.rendered-file-browser div.files ul li.folder > div.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  border: 1px solid #eee;
}
div.rendered-file-browser div.files ul li.folder > div.item span.name {
  flex: 1 1;
  line-height: folder-size;
}
div.rendered-file-browser div.files ul li.folder > div.item span.thumb {
  flex-basis: 45px;
  border: none;
  text-align: center;
}
div.rendered-file-browser div.files ul li.folder > div.item span.thumb > i {
  line-height: 45px;
  font-size: 18px;
}
div.rendered-file-browser div.files ul li.folder > div.item form.renaming {
  margin-top: 8px;
  margin-right: 8px;
}
div.rendered-file-browser div.files ul li.folder > p {
  margin: 8px;
  margin-bottom: 0;
  padding: 0;
}
div.rendered-file-browser div.files ul li.folder.expanded {
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  border-left: 4px solid #eee;
  border-right: 1px solid #eee;
}
div.rendered-file-browser div.files ul li.folder.expanded > div.item {
  padding-left: 0px;
  margin-right: -1px;
  margin-left: -1px;
}
div.rendered-file-browser div.files ul li.folder.expanded.selected {
  border-bottom: 1px solid #ccc;
  border-left: 4px solid #ccc;
  border-right: 1px solid #ccc;
}
div.rendered-file-browser div.files ul li.selected.folder > div.item {
  border: 1px solid #bfbfbf;
}
div.rendered-file-browser
  div.files
  ul
  li.selected.folder
  > div.item
  span.thumb {
  border: none;
}
div.rendered-file-browser div.files ul li.file {
  margin: 4px;
  padding: 0;
}
div.rendered-file-browser div.files ul li.file > div.item {
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin: 0;
}
div.rendered-file-browser div.files ul li.file > div.item span.thumb {
  flex-basis: 120px;
  text-align: center;
  position: relative;
  border: 1px solid #eee;
  margin-bottom: 10px;
}
div.rendered-file-browser div.files ul li.file > div.item span.thumb > i {
  font-size: 40px;
  line-height: 120px;
}
div.rendered-file-browser div.files ul li.file > div.item span.thumb div.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
div.rendered-file-browser div.files ul li.selected.file > div.item span.thumb {
  border: 1px solid #bfbfbf;
}
div.rendered-file-browser
  div.files
  ul
  li.selected.file
  > div.item
  span.thumb
  div.image {
  opacity: 0.8;
}

div.rendered-file-browser p.loading,
div.rendered-file-browser p.empty {
  margin: 16px 0;
}

a {
  text-decoration: none !important;
  color: #425363;
}

div.rendered-react-keyed-file-browser div.action-bar {
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
}
div.rendered-react-keyed-file-browser div.action-bar input[type="search"] {
  display: block;
  flex-grow: 2;
  min-width: 200px;
  padding: 0.25rem 0.5rem;
  line-height: 1em;
  margin-bottom: 0.5rem;
  border: 0.1rem solid #ddd;
}
div.rendered-react-keyed-file-browser div.action-bar .item-actions {
  text-align: right;
  margin: 0;
  padding: 0;
}
div.rendered-react-keyed-file-browser div.action-bar ul.item-actions {
  display: block;
  flex-grow: 1;
  min-width: 200px;
  margin-left: 10px;
  white-space: nowrap;
}
div.rendered-react-keyed-file-browser div.action-bar ul.item-actions li {
  display: inline-block;
  margin: 0;
}
div.rendered-react-keyed-file-browser
  div.action-bar
  ul.item-actions
  li:not(:last-child) {
  margin-right: 0.5rem;
}

div.rendered-react-keyed-file-browser div.files table {
  width: 100%;
  margin-bottom: 2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.rendered-react-keyed-file-browser div.files table th,
div.rendered-react-keyed-file-browser div.files table td {
  font-weight: normal;
  text-align: left;
  margin: 0;
  padding: 0.5rem;
}
div.rendered-react-keyed-file-browser div.files table th {
  font-weight: bold;
}
div.rendered-react-keyed-file-browser div.files table th.size,
div.rendered-react-keyed-file-browser div.files table th.modified,
div.rendered-react-keyed-file-browser div.files table td.size,
div.rendered-react-keyed-file-browser div.files table td.modified {
  text-align: right;
}
div.rendered-react-keyed-file-browser div.files table th.name i,
div.rendered-react-keyed-file-browser div.files table td.name i {
  padding-right: 0.5rem;
}
div.rendered-react-keyed-file-browser div.files table thead th {
  border-bottom: 0.1rem solid #ddd;
}
div.rendered-react-keyed-file-browser div.files table tr:not(:last-child) td {
  border-bottom: 0.1rem solid #eee;
}
div.rendered-react-keyed-file-browser div.files table td.name {
  padding-left: 0.8rem;
}
div.rendered-react-keyed-file-browser div.files table td.name form.renaming {
  display: flex;
  align-items: center;
}
div.rendered-react-keyed-file-browser div.files table td.name form.renaming i {
  flex-grow: 0;
  flex-shrink: 0;
}
div.rendered-react-keyed-file-browser
  div.files
  table
  td.name
  form.renaming
  input[type="text"] {
  flex: 1 1;
}
div.rendered-react-keyed-file-browser div.files table tr td {
  cursor: pointer;
}
div.rendered-react-keyed-file-browser div.files table tr.selected td {
  font-weight: bold;
}
div.rendered-react-keyed-file-browser div.files table tr.selected td input,
div.rendered-react-keyed-file-browser div.files table tr.selected td button {
  font-weight: normal;
}
div.rendered-react-keyed-file-browser div.files table tr.selected td.name {
  position: relative;
}
div.rendered-react-keyed-file-browser
  div.files
  table
  tr.selected
  td.name:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 0.3rem;
  height: 100%;
  background: #1d4567;
}
div.rendered-react-keyed-file-browser div.files table tr.dragover td,
div.rendered-react-keyed-file-browser div.files table tr.dragover th {
  background: #eee;
}

div.rendered-file-browser div.files li.file.pending,
div.rendered-file-browser div.files li.file.dragging,
div.rendered-file-browser div.files li.folder.pending,
div.rendered-file-browser div.files li.folder.dragging {
  opacity: 0.4;
}

div.rendered-file-browser div.files li.file.dragover,
div.rendered-file-browser div.files li.folder.dragover {
  background: #eee;
}

div.rendered-file-browser div.files li.file.selected > div.item,
div.rendered-file-browser div.files li.folder.selected > div.item {
  color: #fff;
  background: #ccc;
}
div.rendered-file-browser div.files li.file.selected > div.item .text-muted,
div.rendered-file-browser div.files li.folder.selected > div.item .text-muted {
  color: #e6e6e6;
}
div.rendered-file-browser div.files li.file.selected > div.item a:not(.btn),
div.rendered-file-browser
  div.files
  li.file.selected
  > div.item.folder
  a:not(.btn),
div.rendered-file-browser div.files li.file.selected > div.item i,
div.rendered-file-browser div.files li.folder.selected > div.item a:not(.btn),
div.rendered-file-browser
  div.files
  li.folder.selected
  > div.item.folder
  a:not(.btn),
div.rendered-file-browser div.files li.folder.selected > div.item i {
  color: #fff;
}

div.rendered-file-browser div.files ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  grid-template-columns: repeat(3, 1fr);
}
div.rendered-file-browser div.files ul li.folder {
  grid-column: 1/4;
}
div.rendered-file-browser div.files ul li.folder > div.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  border: 1px solid #eee;
}
div.rendered-file-browser div.files ul li.folder > div.item span.name {
  flex: 1 1;
  line-height: folder-size;
}
div.rendered-file-browser div.files ul li.folder > div.item span.thumb {
  flex-basis: 45px;
  border: none;
  text-align: center;
}
div.rendered-file-browser div.files ul li.folder > div.item span.thumb > i {
  line-height: 45px;
  font-size: 18px;
}
div.rendered-file-browser div.files ul li.folder > div.item form.renaming {
  margin-top: 8px;
  margin-right: 8px;
}
div.rendered-file-browser div.files ul li.folder > p {
  margin: 8px;
  margin-bottom: 0;
  padding: 0;
}
div.rendered-file-browser div.files ul li.folder.expanded {
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  border-left: 4px solid #eee;
  border-right: 1px solid #eee;
}
div.rendered-file-browser div.files ul li.folder.expanded > div.item {
  padding-left: 0px;
  margin-right: -1px;
  margin-left: -1px;
}
div.rendered-file-browser div.files ul li.folder.expanded.selected {
  border-bottom: 1px solid #ccc;
  border-left: 4px solid #ccc;
  border-right: 1px solid #ccc;
}
div.rendered-file-browser div.files ul li.selected.folder > div.item {
  border: 1px solid #bfbfbf;
}
div.rendered-file-browser
  div.files
  ul
  li.selected.folder
  > div.item
  span.thumb {
  border: none;
}
div.rendered-file-browser div.files ul li.file {
  margin: 4px;
  padding: 0;
}
div.rendered-file-browser div.files ul li.file > div.item {
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin: 0;
}
div.rendered-file-browser div.files ul li.file > div.item span.thumb {
  flex-basis: 120px;
  text-align: center;
  position: relative;
  border: 1px solid #eee;
  margin-bottom: 10px;
}
div.rendered-file-browser div.files ul li.file > div.item span.thumb > i {
  font-size: 40px;
  line-height: 120px;
}
div.rendered-file-browser div.files ul li.file > div.item span.thumb div.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
div.rendered-file-browser div.files ul li.selected.file > div.item span.thumb {
  border: 1px solid #bfbfbf;
}
div.rendered-file-browser
  div.files
  ul
  li.selected.file
  > div.item
  span.thumb
  div.image {
  opacity: 0.8;
}

div.rendered-file-browser p.loading,
div.rendered-file-browser p.empty {
  margin: 16px 0;
}

a {
  text-decoration: none !important;
  color: #425363;
}

.fc .fc-toolbar{
    flex-wrap: wrap;
}
.fc .fc-toolbar.fc-header-toolbar{
    margin-left: 1.5rem;
}
.fc .fc-toolbar-chunk{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 7px;
}
.fc .fc-toolbar-chunk button{
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    text-transform: uppercase;
    margin: 3px;
}
.fc-button-group{
    margin: 3px;
}

.fc-button-group button{
    margin: 0 !important;
}


.fc-direction-ltr .fc-daygrid-event.fc-event-end{
    overflow: auto;
}
.my-popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 37%;
    padding: 30px 80px;
  }
  @media only screen and (max-width: 480px) {
    .my-popup-content {
      top: 30%;
    margin: 20px!important;
    width: 100%;
    padding: 20px 30px;
    height: -webkit-max-content;
    height: max-content;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    .my-popup-content {
      top: 30%;
      margin: 50px 150px!important;
    width: 100%;
    padding: 20px 30px;
    height: -webkit-max-content;
    height: max-content;
    }
  }
  @media only screen and (min-width: 767px) and  (max-width: 1100px) {
    .my-popup-content {
    top: 30%;
    margin: 20px 225px!important;
    width: 100%;
    padding: 20px 30px;
    height: -webkit-max-content;
    height: max-content;
    }
  }
.my-popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].my-popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
  
.my-popup-overlay {
  /* background: rgba(0, 0, 0, 0.16); */
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 90%);
  z-index: 2;
  cursor: pointer;
  }
  [data-popup='tooltip'].my-popup-overlay {
    background: transparent;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.font-related{
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.signatureCanvas {
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  border: 1px solid grey;
}

.lineClamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@font-face {
  font-family: Satoshi-Bold;
  src: url(/static/media/Satoshi-Bold.50eee48d.ttf);
}
@font-face {
  font-family: Satoshi-Regular;
  src: url(/static/media/Satoshi-Regular.4da5359f.ttf);
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(/static/media/Satoshi-Medium.7bec8149.ttf);
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

* {
  /* box-sizing: border-box; */
  /* border: 1px solid green; */
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  padding: 0;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation0.MuiPaper-rounded {
  margin-top: 0.5rem;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 84.5%;
  }
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 76.5%;
  }
}

/* @media only screen and (max-width: 56.25em) {
  html {
    font-size: 76.5%;
  }
} */

/* @media only screen and (max-width: 50.25em) {
  html {
    font-size: 72.5%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 66%;
  }
} */

/*
@media only screen and (max-width: 25em) {
  html {
    font-size: 60%;
  }
} */
/* 

@media only screen and (max-width: 46.25em) {
  html {
    font-size: 70.5%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 66%;
  }
}

*/
.MuiPaper-rounded svg {
  /* position: relative !important; */
  /* height: 55rem !important;
  left: -8rem !important; */
  /* remove both lines if problem with ui */
}
.HoverInfo {
  background: #ffffff !important;
  padding: 1rem !important;
}
.HoverInfo::after {
  background: #ffffff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.__react_component_tooltip {
  padding: 1rem !important;
  z-index: 9999999 !important;
}

.MuiStepConnector-lineVertical {
  margin-top: 10px;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  text-align: left;
}

#zmmtg-root {
  display: none;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

