@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

@font-face {
  font-family: Satoshi-Bold;
  src: url(./assets/fonts/Satoshi/Satoshi-Bold.ttf);
}
@font-face {
  font-family: Satoshi-Regular;
  src: url(./assets/fonts/Satoshi/Satoshi-Regular.ttf);
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(./assets/fonts/Satoshi/Satoshi-Medium.ttf);
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

* {
  /* box-sizing: border-box; */
  /* border: 1px solid green; */
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  padding: 0;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation0.MuiPaper-rounded {
  margin-top: 0.5rem;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 84.5%;
  }
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 76.5%;
  }
}

/* @media only screen and (max-width: 56.25em) {
  html {
    font-size: 76.5%;
  }
} */

/* @media only screen and (max-width: 50.25em) {
  html {
    font-size: 72.5%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 66%;
  }
} */

/*
@media only screen and (max-width: 25em) {
  html {
    font-size: 60%;
  }
} */
/* 

@media only screen and (max-width: 46.25em) {
  html {
    font-size: 70.5%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 66%;
  }
}

*/
.MuiPaper-rounded svg {
  /* position: relative !important; */
  /* height: 55rem !important;
  left: -8rem !important; */
  /* remove both lines if problem with ui */
}
.HoverInfo {
  background: #ffffff !important;
  padding: 1rem !important;
}
.HoverInfo::after {
  background: #ffffff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.__react_component_tooltip {
  padding: 1rem !important;
  z-index: 9999999 !important;
}

.MuiStepConnector-lineVertical {
  margin-top: 10px;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  text-align: left;
}

#zmmtg-root {
  display: none;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
